
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '2214'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '2420'
                    }, entry.hitCount) : null);
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '2899'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3105'
                    }, entry.hitCount) : null);
                }
                function repeatEntry4(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3565'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3771'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName + ' ' + this.extraClassName + '__' + (!this.firstSelectedTerm ? 'empty' : 'selected') }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement('div', {
                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                    'key': '1339'
                }, !this.hideNullOption ? _createElement('div', {
                    'className': 'option',
                    'key': 'null-option',
                    'onClick': () => this.onChange('')
                }, '\n      ', '', this.title, '\n    ') : null, this.loading ? _createElement('div', {
                    'className': 'option',
                    'key': 'null-option',
                    'disabled': true
                }, '...loading...') : null, this.title === 'Make' && this.entries.some(entry => entry.payload === 'Popular') ? [
                    _createElement('div', {
                        'className': 'cm_option_title option',
                        'key': '17681'
                    }, 'Popular Makes'),
                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry2.bind(this)),
                    ,
                    _createElement('div', {
                        'className': 'cm_option_title option',
                        'key': '17686'
                    }, 'All Makes'),
                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry3.bind(this))
                ] : null, this.title !== 'Make' || !this.entries.some(entry => entry.payload === 'Popular') ? [!this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null] : null) : null);
            }, { count: undefined })];
    }
    function repeatSelects2(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '2214'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '2420'
                    }, entry.hitCount) : null);
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '2899'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3105'
                    }, entry.hitCount) : null);
                }
                function repeatEntry4(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3565'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3771'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName + ' ' + this.extraClassName + '__' + (!this.firstSelectedTerm ? 'empty' : 'selected') }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement('div', {
                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                    'key': '1339'
                }, !this.hideNullOption ? _createElement('div', {
                    'className': 'option',
                    'key': 'null-option',
                    'onClick': () => this.onChange('')
                }, '\n      ', '', this.title, '\n    ') : null, this.loading ? _createElement('div', {
                    'className': 'option',
                    'key': 'null-option',
                    'disabled': true
                }, '...loading...') : null, this.title === 'Make' && this.entries.some(entry => entry.payload === 'Popular') ? [
                    _createElement('div', {
                        'className': 'cm_option_title option',
                        'key': '17681'
                    }, 'Popular Makes'),
                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry2.bind(this)),
                    ,
                    _createElement('div', {
                        'className': 'cm_option_title option',
                        'key': '17686'
                    }, 'All Makes'),
                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry3.bind(this))
                ] : null, this.title !== 'Make' || !this.entries.some(entry => entry.payload === 'Popular') ? [!this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null] : null) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '68'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.verifyFitmentTitle || 'Verify fitment with your vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container ' + (this.isVehicleSelected ? 'cm_vehicle-selected' : '') + ' cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary cm_button-primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    GO\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button cm_button-secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, '\n    Clear\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '7507'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? ['This Product Fits'] : null, this.fits === 'no' ? ['This Product Does Not Fit'] : null, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 492 492',
            'key': '80440'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times circled',
            'height': '20px',
            'role': 'img',
            'viewBox': '2 2 12 12',
            'key': '87720'
        }, _createElement('path', {
            'style': { stroke: 'none' },
            'd': 'M 8 2 C 4.6862928 2 2 4.6862997 2 8 C 2 11.3137 4.6862928 14 8 14 C 11.313707 14 14 11.3137 14 8 C 14 4.6862997 11.313707 2 8 2 z M 8 3 C 10.761424 3 13 5.2385763 13 8 C 13 9.199635 12.548037 10.263384 11.84375 11.125 L 4.875 4.15625 C 5.7366165 3.4519627 6.8003651 3 8 3 z M 4.15625 4.875 L 11.125 11.84375 C 10.263384 12.548037 9.199635 13 8 13 C 5.2385763 13 3 10.761424 3 8 C 3 6.8003651 3.4519627 5.7366165 4.15625 4.875 z'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '93990'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null)), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('span', { 'className': 'cm_verify-fitment_vehicle' }, this.vehicleString || 'Vehicle'), _createElement('a', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.changeVehicle
    }, 'Change')), this.fits === 'no' ? _createElement('div', {
        'className': 'cm_verify-fitment_compatible-parts-container',
        'key': '10181'
    }, _createElement('a', {
        'href': this.getVehicleUrl(),
        'className': 'cm_verify-fitment_compatible-parts'
    }, '\n            Click here for products that do fit your vehicle\n          ')) : null))) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '10490'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Please specify ', this.selectFields.join(', '), ' for your ', this.vehicleString), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container ' + (this.isVehicleSelected ? 'cm_vehicle-selected' : '') + ' cmRepeater_selects' },
        _map(this.selects, repeatSelects2.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary button button--primary cm_button-primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    GO\n  ')))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal cmTemplate_universal',
        'key': '17637'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, '\n        ', window.Convermax.config?.verifyFitmentUniversalTitle || 'Universal Fit', '\n        ', _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 492 492',
            'key': '180200'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times circled',
            'height': '20px',
            'role': 'img',
            'viewBox': '2 2 12 12',
            'key': '187480'
        }, _createElement('path', {
            'style': { stroke: 'none' },
            'd': 'M 8 2 C 4.6862928 2 2 4.6862997 2 8 C 2 11.3137 4.6862928 14 8 14 C 11.313707 14 14 11.3137 14 8 C 14 4.6862997 11.313707 2 8 2 z M 8 3 C 10.761424 3 13 5.2385763 13 8 C 13 9.199635 12.548037 10.263384 11.84375 11.125 L 4.875 4.15625 C 5.7366165 3.4519627 6.8003651 3 8 3 z M 4.15625 4.875 L 11.125 11.84375 C 10.263384 12.548037 9.199635 13 8 13 C 5.2385763 13 3 10.761424 3 8 C 3 6.8003651 3.4519627 5.7366165 4.15625 4.875 z'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '193750'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null)), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, window.Convermax.config?.verifyFitmentUniversalText || 'This product may require modification.'))) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '20077'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, '\n        No Fitment Data\n        ', _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 492 492',
            'key': '203860'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times circled',
            'height': '20px',
            'role': 'img',
            'viewBox': '2 2 12 12',
            'key': '211140'
        }, _createElement('path', {
            'style': { stroke: 'none' },
            'd': 'M 8 2 C 4.6862928 2 2 4.6862997 2 8 C 2 11.3137 4.6862928 14 8 14 C 11.313707 14 14 11.3137 14 8 C 14 4.6862997 11.313707 2 8 2 z M 8 3 C 10.761424 3 13 5.2385763 13 8 C 13 9.199635 12.548037 10.263384 11.84375 11.125 L 4.875 4.15625 C 5.7366165 3.4519627 6.8003651 3 8 3 z M 4.15625 4.875 L 11.125 11.84375 C 10.263384 12.548037 9.199635 13 8 13 C 5.2385763 13 3 10.761424 3 8 C 3 6.8003651 3.4519627 5.7366165 4.15625 4.875 z'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '217410'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null)), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, 'This product doesn\'t have fitment application data. Please verify fitment manually.'))) : null);
}
        export const componentNames = []