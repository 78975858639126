//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-392:_1964,_4832,_9608,_2592,_4940,_2208,_5080,_5100;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-392')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-392', "_1964,_4832,_9608,_2592,_4940,_2208,_5080,_5100");
        }
      }catch (ex) {
        console.error(ex);
      }